import { Logger } from 'tslog';

// ? https://tslog.js.org/#/
// ? https://nodejs.org/api/util.html#foreground-colors

/*
  logger.silly('silly');
  logger.trace('trace');
  logger.debug('debug');
  logger.info('info');
  logger.warn('warning');
  logger.error('error');
  logger.fatal('fatal');
 */

export const createLogger = (name: string) =>
  new Logger({
    name,
    prettyLogTemplate:
      '{{yyyy}}.{{mm}}.{{dd}} {{hh}}:{{MM}}:{{ss}}:{{ms}}\t{{logLevelName}}\t[{{name}}]\t',
    prettyLogTimeZone: 'local',
    prettyLogStyles: {
      logLevelName: {
        '*': ['bold', 'black', 'bgWhiteBright', 'dim'],
        SILLY: ['bold', 'cyanBright', 'bgMagenta'],
        TRACE: ['bold', 'white'],
        DEBUG: ['bold', 'blackBright'],
        INFO: ['bold', 'blueBright'],
        WARN: ['bold', 'white', 'bgYellowBright'],
        ERROR: ['bold', 'red'],
        FATAL: ['bold', 'whiteBright', 'bgRed'],
      },
    },
  });

export const logger = createLogger('main');

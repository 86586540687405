// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  batchSdnCalls: process.env['NX_PUBLIC_BATCH_SDN_CALLS'] !== '0',
  appVersion: process.env['NX_PUBLIC_APP_VERSION'],
  appEnv: process.env['NX_PUBLIC_APP_ENV'],
  powerTabsSfID: 'a56Dm000000XxtPIAS',
  panelDataSfID: 'a56TR0000003srtYAA',
  panelDataIframeUrl:
    'https://spinsllc.auth0.com/samlp/sT8Wtkhc67OUzaSvrzuHGFtHaS1fXqQH?RelayState=https://advantageprep.iriworldwide.com/unify-client/sso.html',
  attlabSfid: ['a56Dm000000LI27IAG', 'a56Dm000000LI2IIAW'],
  spinsLlcId: '0013p000029NprlAAC',
  remoteAdminUrl: 'https://admin-dot-spins-insights-portal-dev.uc.r.appspot.com/',
  remoteRetailInsightsUrl: 'https://dev.retail-platform.spins.team',
  remoteBrandInsightsUrl: 'https://dev.brand-insights.spins.team',
  remoteIgnitePlusUrl: 'https://dev.ignite-plus.spins.team',
  remotePartnerInsightsUrl: 'https://spins-partner-insights-dev.uc.r.appspot.com/',
  remotePowerTabsUrl: 'https://dev.powertabs.spins.team/',
  spinsDigestUrl: 'https://dev.digest-ui.spins.team',
  onMaintenance: false,
  allowedCompanyTypesDefaultFilters: ['RETAILER'],
  proxyServer: 'https://proxy-server-dev-cr-909675681934.us-central1.run.app',
  ignitePlusSfID: ['a56TR0000006bGDYAY', 'a56VE000000Z8vZYAS'],
  itemAttributeCodingSfID: 'a56TH0000009j2jYAA',
};
